// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase
} from '../../../../common/edliy_utils-geometric';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-4, 11.5, 10, -2.5],
    keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['image'] =12;
  brd1.options.layer['circle'] =13;
  brd1.options.layer['chart'] =14;
  brd1.options.layer['sector'] =15;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Probability of an Event', '');
  placeLogo(brd1);
  ////////////////////////////////////////////////////////////////////////////////
      var analytics = placeUndo(brd1);
      analytics.setAttribute({visible:()=>i>0})
      analytics.setLabel('Tap to Go to Previous Step');
      analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      analytics.on('over', function () {this.label.setAttribute({visible:true});});
      analytics.on('out', function () {this.label.setAttribute({visible:false});});
      ////////////////////////////////////////////////////
      var erase = placeRedo(brd1);
      erase.setLabel('Tap to Go to Next Step')
      erase.setAttribute({visible:()=>i<3})
      erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      erase.on('over', function () {this.label.setAttribute({visible:true});});
      erase.on('out', function () {this.label.setAttribute({visible:false});});
  /////////////////////////////////////////////////////////////////////////////
      var shuffle = placeShuffle(brd1);
      shuffle.setAttribute({visible:()=>i==0})
      shuffle.setLabel('Tap to Shuffle Events');
      shuffle.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      shuffle.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle.on('out', function () {this.label.setAttribute({visible:false});});
///////////////////////////////////////////////////////////////////////////////////
      var shuffle2 = placeErase(brd1);
      shuffle2.setAttribute({visible:()=>i==3})
      shuffle2.setLabel('Tap to Start Over');
      shuffle2.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      shuffle2.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle2.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////
  var i=0; var j=1; var N=6;
  var n=[3, 3, 5, 4, 4];
  var dice=[];
  var Hidd = placePoint(brd1, -3, 6.5+6, 0, 'black','black');
  //Red Dice
   dice[0] = placeImage(brd1, '/assets/dice-1-red.svg', -0.35-0.55, ()=>Hidd.Y(), 1,20);//
   //dice[0].setAttribute({visible:()=>i>0});
   dice[1] =placeImage(brd1, '/assets/dice-2-red.svg', 1.75-0.55, ()=>Hidd.Y(), 1,70);//
   //dice[1].setAttribute({visible:()=>i>0});
   dice[2] =placeImage(brd1, '/assets/dice-3-red.svg', 3.5-0.55, ()=>Hidd.Y()+0.1, 1,105);//
   //dice[2].setAttribute({visible:()=>i>0});
   dice[3] =placeImage(brd1, '/assets/dice-4-red.svg', 4.35-0.55, ()=>Hidd.Y(), 1,45);//
   //dice[3].setAttribute({visible:()=>i>0});
   dice[4] =placeImage(brd1, '/assets/dice-5-red.svg', 6.1-0.55, ()=>Hidd.Y(), 1, 70);//
   //dice[4].setAttribute({visible:()=>i>0});
   dice[5] =placeImage(brd1, '/assets/dice-6-red.svg', 8.0-0.55, ()=>Hidd.Y()+0.5, 1, 120);//
   //dice[5].setAttribute({visible:()=>i>0});
   var Event = [];
   Event[0] = placeBoldText(brd1, 3, 9.5, 'Event is: Getting an Even Number');
   Event[0].setAttribute({visible:()=>j==0});
   Event[1] = placeBoldText(brd1, 3, 9.5, 'Event is: Getting an Odd Number');
   Event[1].setAttribute({visible:()=>j==1});
   Event[2] = placeBoldText(brd1, 3, 9.5, 'Event is: Getting a Number < 6');
   Event[2].setAttribute({visible:()=>j==2});
   Event[3] = placeBoldText(brd1, 3, 9.5, 'Event is: Getting a Number > 2');
   Event[3].setAttribute({visible:()=>j==3});
   Event[4] = placeBoldText(brd1, 3, 9.5, 'Event is: Getting a Number < 5');
   Event[4].setAttribute({visible:()=>j==4});
//Step#1
   var eggOne = placeImage(brd1, '/assets/eggOne.svg',-1.25, 7.25, 0.5, 0);
   eggOne.setAttribute({visible:()=>i>0});
   var step1 = placeBoldLeftText(brd1, -0.5, 7.5, 'Start with all possible outcomes');
   step1.setAttribute({visible:()=>i>0});
//Step#2
  var eggTwo = placeImage(brd1, '/assets/eggTwo.svg',-1.25, 4.25, 0.5, 0);
  eggTwo.setAttribute({visible:()=>i>1});
    var step2 = placeBoldLeftText(brd1, -0.5, 4.5, 'Find outcomes that belong to the event ');
    step2.setAttribute({visible:()=>i>1});
////////////////////////////////////////////////////////////////////////
//Step#3
    var eggThree = placeImage(brd1, '/assets/eggThree.svg',-1.25, 1.25, 0.5, 0);
    eggThree.setAttribute({visible:()=>i>2});
   var step3 =placeBoldLeftText(brd1, -0.5, 1.5, 'Find ratio M over N');
   step3.setAttribute({visible:()=>i>2});
///////////////////////////////////////////////////////////////////////////////
   var step4 = placeBoldText(brd1, 3, 0.5, ()=>'P(Event) = '+n[j]+'/'+N);
   step4.setAttribute({visible:()=>i>2});
///////////////////////////////////////////////////////////////////////////////
   var Hid = placePoint(brd1, -3, 6.5+6, 0, 'black','black');
   var even=[]; var odd=[];
   even[0] = placeImage(brd1, '/assets/dice-1-red.svg', -0.35-0.55, ()=>Hid.Y(), 1, 20);//
   even[0].setAttribute({visible:()=>j==1 || j==2 || j==4});
   odd[0] = placeImage(brd1, '/assets/dice-2-red.svg',  1.75-0.55, ()=>Hid.Y(), 1, 70);
   odd[0].setAttribute({visible:()=>j==0 || j==2 || j==4});
   even[1] = placeImage(brd1, '/assets/dice-3-red.svg',  3.5-0.55, ()=>Hid.Y()+0.1, 1,105);
   even[1].setAttribute({visible:()=>j==1 || j==2 || j==3 || j==4});
   odd[1] = placeImage(brd1, '/assets/dice-4-red.svg',  4.35-0.55, ()=>Hid.Y(), 1,45);
   odd[1].setAttribute({visible:()=>j==0 || j==2 || j==3 || j==4});
   even[2] = placeImage(brd1, '/assets/dice-5-red.svg',  6.1-0.55, ()=>Hid.Y(), 1, 70);//
   even[2].setAttribute({visible:()=>j==1 || j==2 || j==3});
   odd[2] = placeImage(brd1, '/assets/dice-6-red.svg',  8.0-0.55, ()=>Hid.Y()+0.5, 1, 120);//
   odd[2].setAttribute({visible:()=>j==0 || j==3});

///////////////////////////////TAP OPERATIONS ////////////////////////////////
analytics.on('down', function(){
  if(i==2){
  Hid.moveTo([-3, 6.5-1], 300);
  }
  if(i==1){
  Hidd.moveTo([-3, 6.5+6], 300);
  Hid.moveTo([-3, 6.5+6], 300);
  }
  if(i>0)
  {
  i-=1;
  }
  else
  {
    i=0;
  }
});
/////////////////////////////////////OPERATION 2 /////////////////////////////////
erase.on('down', function(){
  if(i==0){
  Hidd.moveTo([-3, 6.5-1], 300);
  }
  if(i==1){
  Hid.moveTo([-3, 6.5-4], 300);
  }
  else if(i==0)
  {
  Hid.moveTo([-3, 6.5-1], 300);
  }
  if(i<3)
  {
  i+=1;
  }
  else
  {
    i=3;
  }
})
///////////////////////////////////////////OPERATION 3 //////////////////////////////////////
shuffle.on('down', function(){
  Hidd.moveTo([-3, 6.5+6], 300);
  Hid.moveTo([-3, 6.5+6], 300);
  i=0;
  if(j<4)
  {
  j+=1;
  }
  else
  {
  j=0;
  }
})
shuffle2.on('down', function(){
  Hidd.moveTo([-3, 6.5+6], 300);
  Hid.moveTo([-3, 6.5+6], 300);
  i=0;
})
////////////////////////////////////////////END OF OPERATIONS///////////////////////////////
//brd1.unsuspendUpdate();
},
}
export default Boxes;
